<template>
  <div class="p-grid" style="width: 100%;margin: 0;justify-content: space-between">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"
                style="padding-top:0px;background: none;border: none;"/>
    <Button @click="$router.push('/data/detail/dtzz?cm=2&projId='+form.projId)" label="返回" class=" p-button-sm p-button-outlined p-button-secondary"
            style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;"/>
  </div>
  <div class="card">
    <div class="p-grid" style="width: 60%;margin: 0 auto">
      <h4 class="p-col-12" style="font-weight: bold ;padding-left: calc(100px + .5rem);margin-bottom: 2rem">种植记录</h4>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">农事类型：</label>
        <div class="p-col">
          <span class="myTag p-mr-3" @click="$router.push('/plant/plant?projId='+$route.query.projId)">种植</span>
          <span class="myTagSuccess p-mr-3">施肥</span>
          <span class="myTag p-mr-3" @click="$router.push('/plant/irrigation?projId='+$route.query.projId)">灌溉</span>
          <span class="myTag p-mr-3" @click="$router.push('/plant/integration?projId='+$route.query.projId)">水肥一体化</span>
          <span class="myTag p-mr-3" @click="$router.push('/plant/deworming?projId='+$route.query.projId)">除虫</span>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>作业方式：</label>
        <div class="p-col">
         <span class="p-mr-3">
            <RadioButton id="fetilize1" name="workType" value="0"
                         :class="{'p-invalid':(v$.form.workType.$invalid && submitted)}"
                         v-model="form.workType"></RadioButton>&nbsp;
                         <label for="fetilize1" class="radio-label">人工</label>
         </span>
          <span>
            <RadioButton id="fetilize2" name="workType" value="1"
                         :class="{'p-invalid':(v$.form.workType.$invalid && submitted)}"
                         v-model="form.workType"></RadioButton>&nbsp;
                         <label for="fetilize2" class="radio-label">机械</label>
         </span>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.workType.$invalid && submitted">请选择作业方式</span>

      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>施肥方式：</label>
        <div class="p-col">
         <span class="p-mr-3">
            <RadioButton id="fetilize3" name="fertilizeType" value="0"
                         :class="{'p-invalid':(v$.form.fertilizeType.$invalid && submitted)}"
                         v-model="form.fertilizeType"></RadioButton>&nbsp;
                         <label for="fetilize3" class="radio-label">苗期追肥</label>
         </span>
          <span class="p-mr-3">
            <RadioButton id="fetilize4" name="fertilizeType" value="1"
                         :class="{'p-invalid':(v$.form.fertilizeType.$invalid && submitted)}"
                         v-model="form.fertilizeType"></RadioButton>&nbsp;
                         <label for="fetilize4" class="radio-label">分叶期追肥</label>
         </span>
          <span class="p-mr-3">
            <RadioButton id="fetilize5" name="fertilizeType" value="2"
                         :class="{'p-invalid':(v$.form.fertilizeType.$invalid && submitted)}"
                         v-model="form.fertilizeType"></RadioButton>&nbsp;
                         <label for="fetilize5" class="radio-label">拔节期追肥</label> 
         </span>
          <span class="p-mr-3">
            <RadioButton id="fetilize6" name="fertilizeType" value="3"
                         :class="{'p-invalid':(v$.form.fertilizeType.$invalid && submitted)}"
                         v-model="form.fertilizeType"></RadioButton>&nbsp;
                         <label for="fetilize6" class="radio-label">收割</label>
         </span>
          <span class="p-mr-3">
            <RadioButton id="fetilize7" name="fertilizeType" value="4"
                         :class="{'p-invalid':(v$.form.fertilizeType.$invalid && submitted)}"
                         v-model="form.fertilizeType"></RadioButton>&nbsp;
                         <label for="fetilize7" class="radio-label">收割后管理</label> 
         </span>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.fertilizeType.$invalid && submitted">请选择种植方式</span>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>记录时间：</label>
        <div class="p-col">
          <Calendar date-format="yy-mm-dd" :class="{'p-invalid':(v$.form.opeTime.$invalid && submitted)}" v-model="form.opeTime"/>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.opeTime.$invalid && submitted">请选择录入时间</span>

      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">操作人：</label>
        <div class="p-col">
          <InputText v-model="form.opeName"/>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">农事照片：</label>
        <div class="p-col p-d-flex">
          <div class="upload-div"
               @click="uploadImg()">
            <img style="width: 30%;height: 30%" src="../../../../../assets/images/add.png">
          </div>
          <div class="p-mr-3" style="position: relative" v-for="(item,index) in form.images">
            <img style="height: 100px;" :src="$minioUrl+item.imgPath">
            <span @click="removeImg(index)"
                  style="position: absolute;width: 20px;height: 20px;background: white;border: 1px solid #CED4DA;border-radius: 20px;right: -7px;top: -7px;display: flex;align-items: center;justify-content: center">
              <i class="pi pi-times" style="font-size: 10px"></i>
            </span>
          </div>

        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>面积：</label>
        <div class="p-col">
          <InputNumber min="0" style="vertical-align:middle" :class="{'p-invalid':(v$.form.area.$invalid && submitted)}" v-model="form.area" class="p-mr-3" :minFractionDigits="2"/>
          亩
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.area.$invalid && submitted">请输入面积</span>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">选择肥料：</label>
        <div class="p-col">

          <div style="font-weight: bold;color:#12A25D;cursor:pointer;" @click="addFetilize">添加使用肥料</div>
          <div style="margin-top: 10px;border: 1px solid #ced4da;width: 550px" v-if="form.fertilizeLists.length>0">
            <div v-for="(item,index) in form.fertilizeLists" style="display: flex;height: 50px;align-items: center;padding: 0 10px;width: 550px"
                 :style="{borderBottom:index!==form.fertilizeLists.length-1?'1px solid #ced4da':null}">
              <div style="width:200px;height: 100%;padding-right: 10px;border-right: 1px solid #ced4da">
                <div style="line-height: 30px">{{ item.name }}</div>
                <span style="color: #79828a;line-height: 20px">{{ item.mfr }}</span>
              </div>
              <div style="padding: 20px;height:100%;width:270px;border-right: 1px solid #ced4da;display: flex;align-items: center">
                <InputNumber min="0" style="vertical-align:middle" :class="{'p-invalid':(!item.kilo && submitted)}" class="p-mr-3" v-model="item.kilo" :minFractionDigits="2"/>
                公斤
              </div>
              <div style="width: 80px">
                <Button class="p-button-link" @click="removeFerItem(index)">删除</Button>
              </div>
            </div>
          </div>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="form.fertilizeLists.filter(item=>{return !item.kilo}).length>0 && submitted">请输入公斤数</span>
        <div class="p-col-12 p-mt-2" style="padding-left:calc(100px + 0.5rem)">
          <div v-if="suggest!=null" style="background: lightyellow;padding: .6rem;border-radius: 5px;">
            <i class="pi pi-info-circle" style="position:relative;top:1px"></i>&nbsp;专家建议：<span class="p-mr-3 ">{{ suggest ? suggest.fertilizerType : '-' }}</span>
            <span v-if="suggest&&suggest.fertilizerList.length>0" v-for="item in suggest.fertilizerList">
              {{ item.name }}{{ item.num }}斤/亩
            </span>
          </div>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>费用：</label>
        <div class="p-col">
          <InputNumber min="0" style="vertical-align:middle" :class="{'p-invalid':(v$.form.price.$invalid && submitted)}" v-model="form.price" class="p-mr-3" :minFractionDigits="2"/>
          元
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.price.$invalid && submitted">请输入费用</span>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">备注：</label>
        <div class="p-col">
          <Textarea v-model="form.comments" :autoResize="true" rows="4" cols="60"/>
        </div>
      </div>
      <div style="margin-left: calc(100px + .5rem)">
        <Button class="p-mr-3" @click="submit">提交</Button>
        <Button class="p-button-outlined p-button-secondary" @click="$router.push('/data/detail/dtzz?cm=2&projId='+form.projId)">
          取消
        </Button>
      </div>
    </div>
    <select-fetilize v-if="ferDisplay" ref="addFer" @selectedResult="selectedFer"></select-fetilize>
  </div>
</template>

<script>
import SelectFetilize from '@/views/modules/data/overview/plant/selectFetilize';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  name: 'fetilize',
  components: {SelectFetilize},
  data() {
    return {
      breadcrumb: {
        home: {label: '种植项目', to: '#'},
        items: [
          {label: '农事记录与指导', to: '/data/overview'},
          {label: '查看数据', to: '#'},
        ],
      },
      suggest:null,
      submitted: false,
      form: {
        id: null,
        projId: null,
        workType: null,
        fertilizeType: null,
        opeTime: null,
        opeName: JSON.parse(localStorage.getItem('userinfo')).user.name,
        area: null,
        price: null,
        comments: null,
        images: [],
        fertilizeLists: [],
      },
      ferDisplay: false,
    };
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      form: {
        id: {},
        projId: {},
        workType: {required},
        fertilizeType: {required},
        opeTime: {required},
        opeName: {},
        area: {required},
        price: {required},
        comments: {},
      },
    };
  },
  created() {
    this.form.projId = this.$route.query.projId;
    if (localStorage.getItem('suggestInfo' + this.form.projId)) {
      this.suggest = JSON.parse(localStorage.getItem('suggestInfo' + this.form.projId));
    }
    let id = this.$route.query.id;
    if (id) {
      this.getData(id);
    }
  },
  methods: {
    getData(id){
      this.$axios.get("/planFertilize/getById/"+id).then(res=>{
        res.data.opeTime = new Date(res.data.opeTime)
        this.form = res.data;
      })
    },
    uploadImg() {
      const _this = this;
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = function() {
        let file = this.files[0];
        let formData = new FormData();
        formData.append('file', file);
        _this.$axios.post('/minio/uploadFile', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
        }).then((res) => {
          _this.form.images.push({
            imgPath: res.data,
          });
        });
      };
      input.click();
    },
    removeImg(index) {
      this.form.images.splice(index, 1);
    },
    addFetilize() {
      this.ferDisplay = true;
      this.$nextTick(() => {
        this.$refs.addFer.init();
      });
    },
    selectedFer(list) {
      if (list && list.length > 0) {
        list.forEach(item => {
          this.form.fertilizeLists.push({
            fertilizerId: item.id,
            name: item.name,
            mfr: item.mfr,
            kilo: null,
          });
        });
      }
      this.ferDisplay = false;
    },
    removeFerItem(index) {
      this.form.fertilizeLists.splice(index, 1);
    },
    submit() {
      this.submitted = true;

      if (this.v$.$invalid || this.form.fertilizeLists.filter(item => {return !item.kilo;}).length > 0) {
        return;
      }

      this.$axios.post('/planFertilize/addOrUpdate', this.form).then(() => {
        this.$toast.add({severity: 'success', summary: '新增成功', life: 3000});
        this.$router.push('/data/detail/dtzz?cm=2&projId=' + this.form.projId);
      });
    },
  },
};
</script>

<style scoped>
label {
  margin:0;
  width: 100px;
  justify-content: flex-end;
}

.p-field > label {
  margin-bottom: 0;
}

</style>